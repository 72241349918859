/* This is the global CSS file */

body {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    color: #858796;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.imgCommon{
    width: 100%;
    max-width: 100%; /* Optional, to maintain responsiveness */
  max-height: 100%; /* Optional, to prevent overflow */
}

.heightCard{
    height: 90px;
}

.ant-typography h1,
.ant-typography h2,
.ant-typography h3,
.ant-typography h4,
.ant-typography h5,
.ant-typography h6,
.ant-card-head-title,
.ant-modal-title {
    font-family: 'Oswald', sans-serif !important;
}

.ant-table-thead {
    font-family: 'Oswald', sans-serif !important;
}


html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%
}

.ml-10 {
    margin-left: 10px;
}

.centered {
    text-align: center;
}

.mr-10 {
    margin-right: 10px;
}

.mt-20 {
    margin-bottom: 20px;
}

.skeletonStyle {
    padding: 20px;
}

.underline:hover {
    text-decoration: underline;
    cursor: pointer;
}

.chart-container {
    height: 250px;
    width: 100%;
    /* Adjust the height as needed */
}


.primaryColor {
    color: #0a4776;
}

.loadingCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loadingColApp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.pb-10 {
    padding-bottom: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.pt-20 {
    padding-top: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.p-10 {
    padding: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.h-fullvh {
    height: 100vh;
}

.text-base {
    font-size: 16px;
}

.text-34 {
    font-size: 34px;
}

.noPaddingBodyCard{
    .ant-card-body{
        padding: 0 0 0 24px !important;
    }
}

.clickedClr{
    .ant-card-body {
        background-color: aliceblue;
    }
    .ant-card-head{
        background-color: aliceblue;
    }
}

.text-32 {
    font-size: 32px;
}

.font-bold {
    font-weight: 700;
}

.m-0 {
    margin: 0;
}

.m-10 {
    margin: 10px;
}

.newLoadingSkeleton{ .ant-skeleton-paragraph li {
    background-color: whitesmoke !important;
    padding: 15px !important;
}}

.pointer {
    cursor: pointer;
}

@media (max-width: 1000px) {
    .noShow {
        display: none !important;
    }
}

.clr-blk {
    color: black !important;
}

.h-18 {
    height: 18px;
}

/* #root {
    max-width: 1920px;
    max-height: 1080px;
    margin: 0 auto;
  } */

* {
    box-sizing: border-box;
}

/* This file is for adding styles for component */
.centerLogo {
    justify-content: center;
    display: flex;
    align-items: center;
}

.paddingBottom14 {
    padding-bottom: 14px;
}

.gapsixteen {
    margin-bottom: 2vh;
}

.p-10 {
    padding: 10px;
}

.ant-menu-item-disabled {
    cursor: wait !important;
}

.rowGraph {
    /* padding-right: 25px; */
    margin-top: 10px;
    /* padding-left: 25px; */
}

.lineGraph {
    width: 100%;
    height: 100%;
    position: relative;
}

.csvLink {
    float: right;
}

.mapContainer {
    width: 100%;
    height: calc(90vh - 21rem);
    border: 5px solid #ededed;
}

.gridStyle {
    text-align: left;
    border: none;
    box-shadow: none;
}

.ant-card .ant-card-grid {
    box-shadow: none;
    width: 100%;
}

.NoHeaderBorder{
    .ant-card-head{
    border: none !important;
}}

.ant-card-bordered {
    border: none;
    /* height: 100%; */
}

div.selected-card {
    /*border: 1px solid #0a4776 !important;
    /* Change the border style to highlight the card */
    box-shadow: 0 0.5rem 1rem #b1b3b5 !important;
}

.cardUnselected {
    border: 1px solid #d4d4d4 !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

.box-border {
    border-style: groove;
    height: 90%;
    width: 90%;
    position: relative;
    display: flex;
    justify-content: center;
    /* Horizontally center */
    align-items: center;
    background-color: black;
    color: white;
}

.ant-typography {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    ;
}

.bordered-div {
    border: 1px solid #c3bfbf;
}

.circleStyle {
    display: inline-block;
    background-color: #dbd9d9;
    border-radius: 50%;
    width: 25px;
    height: 20px;
    border: 2px dotted #998d8d;
}

.rectangleStyle {
    display: inline-block;
    border: 1px solid #998d8d;
    border-radius: 1px;
    width: 24px;
    height: 20px;
}

@media (max-width: 768px) {
    .circleStyle {
        width: 15px !important;
        ;
        height: 15px !important;
        ;
    }

    .rectangleStyle {
        width: 15px !important;
        ;
        height: 7px !important;
        ;
    }

}

.ant-layout-header {
    height: 30px;
}

/* 
  .checkBoxStyle {
    float: right !important;
  } */

/* Media query for viewport width <= 768px */
@media (max-width: 768px) {
    .checkBoxStyle {
        float: left !important;
    }
}

.centerSpin {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 80vh;
}

div.timeline_container div.ant-timeline-item-label {
    width: calc(25% - 12px) !important;
}

div.timeline_container div.ant-timeline-item-content {
    left: calc(30% - 4px) !important;
    width: calc(89% - 14px) !important;
}

div.timeline_container div.ant-timeline-item-tail,
div.ant-timeline-item-head {
    left: 28% !important;
}

.heightMonitorYourSystem {
    height: 100%;
}

.heightOtherConcerns {
    height: 100%
}

/* Media query for viewport width = 1920px */
@media (width: 1920px) {
    .heightMonitorYourSystem {
        height: 90vh;
    }

    .heightOtherConcerns {
        height: 57vh;
    }
}

.custom-map-style .gm-fullscreen-control {
    display: none !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #e0f7fa;
    /* Track color (light blue) */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #969591;
    /* Scrollbar color (blue) */
    border-radius: 10px;
    /* Round scrollbar corners */
}
.ant-menu-item-selected{
.colorChangeWhenSelected{
  /* color: #38B34A !important; */
  font-size: 24px !important;  
}
}

.inter{
    font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
}

.hidden {
    display: none;
}

.float-right{
    float: right !important;
}

.colorChangeWhenSelected{
    /* color: #38B34A !important; */
    font-size: 24px !important;  
  }

.ant-menu-title-content {
    font-family: Oswald;
font-size: 16px;
font-weight: 400;
line-height: 23.71px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #969591;
    /* Color when hovered (darker blue) */
}

/* Custom scrollbar for Firefox */
.custom-scrollbar {
    scrollbar-width: thin;
    /* Scrollbar thickness */
    scrollbar-color: #969591 #e0f7fa;
    /* Scrollbar color and track color */
}

.content {
    padding: 20px 20px;
    /* background-color: #fff; */
    height: calc(100vh - 64px);
    /* min-height: 600px; */
    overflow: auto;
    /* Add scrollbars when content overflows */
    /* Adjust the height of content area */
}

.select-disabled.ant-select-disabled .ant-select-selector {
    background-color: white !important;
    color: black !important;
    cursor: default !important;
}

.clrDrawer{
    .ant-drawer-body{
        background: #011A2E;
    }
}

.scrollY {
    overflow-y: auto;
}

.centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.widthResponsive {
    width: 100%;
    /* Adjust max-width as needed */
    font-size: 20px;
}


.loadingColGraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 700px;
}

.loadingColGraphAuto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
}

.chargerDiv{
display: flex;
flex-direction: row;
align-items: center;
gap: 10,
}

.outerChargerDiv{
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.emptyStyle {
    margin: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.text-nowrap {
    color: #0a4776;
    white-space: nowrap;
    line-height: 1.5;
}

.h-18 {
    height: 18px;
}

.h-16 {
    height: 16px;
}

.h-13 {
    height: 13px;
}

.centered16 {
    display: flex;
    align-items: center;
    padding: 16px
}

.avatar {
    left: 0;
    margin-right: 12px;
    flex-shrink: 0
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
}

.chargerTabs {
    /* margin-top: 20px; */
    /* border-radius: 10px; */

    .ant-tabs-nav {
        padding: 10px;
        background: #FFFFFF;
        border-radius: 10px;
    }
}

.mainBackButton:not(.disabled):hover {
    background-color: lightgray;
    border-radius: 100%;
    padding: 5px;
}

.mainBackButton.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.imageDiv {
    position: relative;
    margin: 10px;
}

.actualImage {
    height: 40px;
    width: auto;
    max-width: 100%
}

.selectDiv {
    justify-content: center;
    align-items: center;
    display: flex;
}

.versionDiv {
    position: absolute;
    margin-top: 10%;
    bottom: 0;
    left: 20px;
    right: 0;
    padding: 10px
}

/* .gm-style-iw-d{
    overflow: auto !important;
    width: auto !important;
    height: auto !important;
}

.gm-style-iw-chr{
    display: none !important;
} */


.darkmobile{
    background-color: #011A2E !important;
    color: white !important;
  }

  .whiteBtn{
    color: white !important;
  }

.insideSelectMobile {
    .ant-select-selector{
        background: #E9EEEE !important;
    }
    width: 100%;
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.insideSelect {
    .ant-select-selector{
        background: #E9EEEE !important;
    }
    width: 200px;
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.collapseDiv {
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.avatarAdditional {
    margin-right: 10px;
    background-color: #ADD8E6;
    color: #f0f0f0,
}

.clickableOpacity:hover {
    background-color: lightgray;
    padding: 2px;
    border-radius: 5px;
}

.centeredFlex {
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
}
  