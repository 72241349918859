.gl-maps-container {
    .ant-card-body {
        padding-top: 0;
    }

    height: 90vh;
    overflow-y: clip;
}

.gl-maps-container-global {
    overflow-y: clip;
}

.gl-maps-map-style {
    border: 1px solid #d4d4d4;
    position: 'relative';
}

.subheading {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
}

.gl-maps-selected-site-location {
    padding: 5px;
    background-color: white;
    border: 1px solid #d4d4d4;
}

.commonFlexDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.additonal {
    text-align: center;
    margin: 0;
}