.general{

    .heightfull{
        height: 100%;
    }

    .centered-column {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .fuel{
        font-family: SF Pro Display;
font-size: 48px;
font-weight: 700;
line-height: 57.28px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #269837;
      }

      .fuel2{
        font-family: SF Pro Display;
/* font-size: 36px; */
font-weight: 700;
line-height: 57.28px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #269837;
      }

      .fuel3{
        font-family: SF Pro Display;
font-size: 24px;
font-weight: 700;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #269837;
      }

      .FuelDesc:first-letter {
        text-transform: uppercase;
    }

    .textTypo {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        line-height: 21.78px;
        width: 100%; /* Keep the fixed width */
        white-space: nowrap; /* Prevent text wrapping */
        overflow: hidden; /* Hide overflowing text */
        text-overflow: ellipsis; /* Add ellipsis for overflow */
        text-align: center; /* Ensure the text stays centered */
    }

    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

      .image-style {
        height: 60px;
        width: 100%;
        object-fit: contain;
      }

      .flex-space-around{
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      
      .flex-start {
        display: flex;
        justify-content: flex-start;
      }

      .flex-col-center{
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .flex-center{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .scrollable-column {
        display: flex;
        flex-direction: column;
        max-height: 340px;
        min-height: 340px;
        overflow-y: auto;
      }

      .textCenter{
        text-align: center
      }

      .cover-image {
        width: 100%;
        object-fit: cover;
        max-height: 269px;
        min-height: 269px;
      }

      .space-between-center {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      
.container-charger {
    background-color: #f5f5f5;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    padding: 8px;
    font-family: SF Pro Display;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.cardBorder{
    border: 1px solid #DBDFDF
}

.ant-table-row{
    line-height: 18px;
}

.cardColor {
    background: #F2F3F3;
}
@media (width > 768px) {
.borderRight{
    border-right: 1px solid #d4d4d4;
}
}

.level5{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.level51{
    align-items: center;
}

.level6{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.nolevel2 {
    font-family: 'SF Pro Display';
font-size: 20px;
font-weight: 500;
line-height: 23.87px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #1674EA;
}

.full-height-row {
    display: flex;
    flex-wrap: wrap;
    /* Ensures responsive behavior */
}

.NoHeaderBorder{
    .ant-card-head{
    border: none !important;
}}

.h-40{
    height: 40px;
    margin-top: 5px;
}

.typoNumber{
    font-family: 'SF Pro Display';
    color: #269837;
/* font-size: 48px; */
font-weight: 700;
line-height: 57.28px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
}

.levelfull {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.number {
    font-family: SF Pro Display;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    color: #1675EA;
}

.level {
    font-family: Oswald;
    font-size: 18px;
    font-weight: 400;
    line-height: 26.68px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;    
}

.level2 {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.level3 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.level4 {
    align-items: center;
    align-self: center;
    align-content: center;
}

.textSub {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
}

.sub {
    font-family: SF Pro Display;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
}

.right-full-height {
    display: flex;
    flex-direction: column;
}

.full-height-card {
    flex: 1;
    /* Take full height of the parent row */
}

.titleHeader {
    .ant-card-head-title {
        font-size: 25px !important;
    }
}

.ant-card-head-title {
    font-size: 18px;
    font-weight: 400;
    color: #011A2E;
}

.h-24 {
    height: 24px;
}

.text-right-dash {
    text-align: right;
}

.h-26 {
    height: 26px;
}

.each {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
}

.first {
    justify-content: start;
}

.second {
    justify-content: center;
}

.third {
    justify-content: end;
}

.colFont2{
    font-family: SF Pro Display;
font-size: 16px;
font-weight: 600;
line-height: 19.09px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
}

@media (max-width: 768px) {
    .colFont {
        font-size: 12px;
    }

    .first {
        justify-content: center;
    }

    .second {
        justify-content: center;
    }

    .third {
        justify-content: center;
    }
}

.ant-progress-text{
    font-family: Inter !important;
font-size: 14px !important;
font-weight: 600 !important;
line-height: 16.94px !important;
text-align: center !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important; 
}

.equal-height-row {
    display: flex;
    flex-wrap: wrap;
}

.equal-height-card {
    display: flex;
    height: 142px !important;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    /* Allow cards to grow */
}

.equal-height-card2 {
    display: flex;
    height: 300px !important;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    /* Allow cards to grow */
}

.equal-height-card4{
    display: flex;
    flex-direction: column;
    height: 100%;
} 

@media (max-width: 768px) {
    .equal-height-card {
        display: flex;
        min-height: 142px;
        overflow-y: auto;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        flex-grow: 1;
        /* Allow cards to grow */
    }
    .equal-height-card2 {
        display: flex;
        height: 300px !important;
        overflow-y: auto;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        flex-grow: 1;
        /* Allow cards to grow */
    }
}

.equal-height-card .ant-card-body {
    flex-grow: 1;
    /* Ensure card content stretches to the full height */
}

.custom-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.progress-percent {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}

.progress-bar {
    width: 10px;
    /* Adjust width to your preference */
    height: 100px;
    /* Height of the progress bar */
    background-color: #e0e0e0;
    /* Background color of the empty bar */
    border-radius: 5px;
    position: relative;
    margin-bottom: 8px;
}

.progress-bar-inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 5px;
    transition: height 0.3s ease;
    /* Smooth transition for progress */
}

.equipment-name {
    font-size: 14px;
    margin-top: 8px;
}
}