.charger {
    .card-custom {
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        height: 100%;
    }

    .card-grid-custom {
        width: 100%;
        height: auto;
    }

    /* Pie Chart Container */
    .pie-chart-container {
        height: 75%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* Typography Styles */
    .typography-title {
        font-size: 2vh;
        text-align: left;
    }

    .typography-value {
        font-size: 34px;
        text-align: left;
        margin: 10px 0;
        font-weight: 700;
    }

    /* Aligning Tooltip Title to Left */
    .tooltip-title {
        font-size: 16px;
        text-align: left;
    }

    .tooltip-value {
        font-size: 34px;
    }

    /* General styles */
    .cardUnselected {
        /* Add any specific styles for cardUnselected if needed */
    }

    .heightCard {
        height: 100%;
        min-height: 58vh;
        /* margin-bottom: 20px; */
    }

    .card-row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .status-name {
        margin-left: 10px;
    }

    .circleStyle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #ccc;
        /* default grey circle */
    }

    .grey-img {
        filter: grayscale(1);
    }

    /* Image styles */
    .charger-img {
        height: auto;
        right: 23px;
    }

    .textTypoMain {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        text-align: left;
    }

    .textTypo {
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.78px;
        text-align: left;
    }

    .headerRunning {
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 18.15px;
        text-align: left;
    }

    .subheading {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: left;
    }

    .cardCharger {
        height: 340px;
        overflow-y: auto;
    }

    .img-container {
        img {
            height: 180px;
            width: 135px;
        }
    }

    .h-12 {
        height: 12px;
    }

    /* Power status styles */
    .power-status-row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .power-status-row .circleStyle {
        margin-right: 10px;
    }

    .chargerSection {
        .card-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 25px;
        }

        .grey-img {
            filter: grayscale(1);
        }

        .imageCss {
            max-height: 300px;
            max-width: 300px;
            /* padding: 20px; */
        }

        .cssForRunningStatus {
            width: fit-content;
        }

        @media (max-width: 768px) {
            .imageCss {
                height: 100%;
                width: 100%;
            }

            .cssForRunningStatus {
                padding: 15px;
                max-width: 100%;
            }
        }

        .backButton {
            margin: 20px 0px 0px 20px;
        }
    }
}