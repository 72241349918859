.dashboard {
    .margin-5 {
        padding: 10px;
    }
    

    min-height: calc(100vh - 104px);

    .imageDiv {
        position: relative;
        margin: 10px;
    }

    .clr-blk {
        color: black !important;
    }

    .actualImage {
        height: 40px;
        width: auto;
        max-width: 100%
    }

    .h-18 {
        height: 18px;
    }

    .selectDiv {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .versionDiv {
        position: absolute;
        margin-top: 10%;
        bottom: 0;
        left: 20px;
        right: 0;
        padding: 10px
    }

    .ant-menu-dark .ant-menu-item-selected {
        background-color: #012542;
        border-right: 5px solid #FFDE17;
        border-radius: 0px;
    }

    .ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title {
        border-right: 5px solid #FFDE17;
    }

    .content {
        /* Adjust based on your header height */
        overflow-y: auto;
        /* Allow vertical scrolling */
        padding: 16px;
        /* Optional padding */
    }

    .header {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
    }

    .collapseDiv {
        display: flex;
        align-items: center
    }

    .centerLogo {
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .mainBackButton:not(.disabled):hover {
        background-color: lightgray;
        border-radius: 100%;
        padding: 5px;
    }

    .mainBackButton.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .p-10 {
        padding: 10px;
    }

    .pl-10 {
        padding-left: 10px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    @media (max-width: 1000px) {
        .noShow {
            display: none !important;
        }
    }

    .text-base {
        font-size: 16px;
    }
}